// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {


  redirectLinkBaseUrl:'https://niha.app/',

    //  Live Url for Direct Links

    // redirectLinkBaseUrl:'https://b-intouch.me',



// Staging Server API's URL

// baseURL: 'https://staging.niha.app/api/',
// imageUrl: 'https://staging.niha.app/public/uploads/user_images/',
// service_icons: 'https://staging.niha.app/public/uploads/service_icons_v2/',
// round_icons:'https://staging.niha.app/public/uploads/service_icons_v2/round/',
// slug_images :'https://staging.niha.app/public/uploads/user_files/', 
// qr_images: 'https://staging.niha.app/public/uploads/user_qrcode/',
// contact_images:'https://staging.niha.app/public/uploads/user_images/',
// lost_found: 'https://staging.niha.app/public/uploads/user_files/', 
// tutorials : 'https://staging.niha.app/public/uploads/tutorials/', 
// export_event :'https://staging.niha.app/api/export_event/?event_id=',
// export_contact:'https://staging.niha.app/api/export_contact/',
// export_contacts:'https://staging.niha.app/api/export_contacts/',
// contactformbaseURL: 'https://staging.niha.app/',


// Niha Live Server API's Url


baseURL: 'https://api.niha.app/api/',
imageUrl: 'https://api.niha.app/public/uploads/user_images/',
service_icons: 'https://api.niha.app/public/uploads/service_icons_v2/',
round_icons:'https://api.niha.app/public/uploads/service_icons_v2/round/',
service_icons_transparent: 'https://api.niha.app/public/uploads/service_icons_v2/transparent/',
round_icons_transparent:'https://api.niha.app/public/uploads/service_icons_v2/transparent/',
slug_images :'https://api.niha.app/public/uploads/user_files/', 
qr_images: 'https://api.niha.app/public/uploads/user_qrcode/',
contact_images:'https://api.niha.app/public/uploads/user_images/',
lost_found: 'https://api.niha.app/public/uploads/user_files/', 
tutorials : 'https://api.niha.app/public/uploads/tutorials/', 
export_event :'https://api.niha.app/api/export_event/?event_id=',
export_contact:'https://api.niha.app/api/export_contact/',
export_contacts:'https://api.niha.app/api/export_contacts/',
contactformbaseURL: 'https://api.niha.app/',

 stagingLinkUrl:'https://niha.io/bapp/api/',
  liveLinkUrl:'https://niha.app/',

  production: false,
  firebase: {
    apiKey: "AIzaSyD2yivQtq-Tjqpe8hNmQoyhKHGBcv-9o38",
    authDomain: "omega-art-265906.firebaseapp.com",
    projectId: "omega-art-265906",
    storageBucket: "omega-art-265906.appspot.com",
    messagingSenderId: "653339509277",
    appId: "1:653339509277:web:f6cab6d293b27d718fbcf4",
    measurementId: "G-2YV3PFPEQ4"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.



