import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { async } from '@angular/core/testing';
import { ToastrManager } from "ng6-toastr-notifications";
import { NgxSpinnerService } from "ngx-spinner";
import { ApiService } from "../api-service/api.service";
import { Platform } from '@angular/cdk/platform';
import { HttpBackend, HttpClient } from '@angular/common/http';
import { coerceStringArray } from '@angular/cdk/coercion';
import { environment } from 'src/environments/environment';


declare var google: any;
@Injectable({
  providedIn: 'root'
})
export class CommonService {
  dropdownSettings: any;
  addServiceDropSetting: any;
  countryCodeList: Array<any> = [];
  token = 'token';
  lat: any;
  long: any;
  key = 'XThZpPIzIIne69KIjpnF_Tg9mpdoakmw'
  slug = 'slug_data';
  current_address: any = '';
  testBrowser: boolean;
  wazeData: string;
  chromeData: string;
  destination_latitude: any = '';
  destination_longitude: any = '';
  current_location_latitude: number;
  current_location_longitude: number;
  destination_addressvar: string;

  constructor(
    private toaster: ToastrManager,
    private handler: HttpBackend, private http: HttpClient,
    private api: ApiService,
    private spinner: NgxSpinnerService,
    private platform: Platform,
  ) {

    this.dropdownSettings = {
      enableCheckAll: false,
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 5,
      allowSearchFilter: false
    };
    this.addServiceDropSetting = this.dropdownSettings;
    this.addServiceDropSetting.singleSelection = true;
  }

  success(message: string): void {
    this.toaster.successToastr(message, '', { maxShown: 1 },);
  }
  error(message: string): void {

    this.toaster.errorToastr(message, '', {
      maxShown: 1
    }
    );
  }

  getToken(): any {
    try {
      return localStorage.getItem(this.token);
    } catch (e) { }
    return null;
  }

  /* === NFC redireation=== */
  redirectToUrl(data: any, slug: string, title: string) {
    switch (slug) {
      case 'instagram': {
        if (this.platform.ANDROID && this.platform.isBrowser) {
          var url: any = `https://www.instagram.com/${data}`;
          var bundlename: any = `instagram://user?username=${data}`;
        }
        else if (this.platform.IOS && this.platform.SAFARI) {
          var url: any = `https://www.instagram.com/${data}`
          var bundlename: any = `https://www.instagram.com/${data}`;
        }
        else {

          var url: any = `https://www.instagram.com/${data}`
          var bundlename: any = `https://www.instagram.com/${data}`;
        }
        let dataUrl: any = { url, bundlename };
        return dataUrl;
      }
      case 'tiktok': {
        const url = `https://www.tiktok.com/@${data}`
        const bundlename: any = `https://www.tiktok.com/@${data}`;
        let dataUrl = { url, bundlename };
        return dataUrl;
      }
      // case 'snapchat': {
      //   const url = `https://www.snapchat.com/add/${data}`
      //   const bundlename: any = `https://www.snapchat.com/add/${data}`;
      //   let dataUrl = { url, bundlename };
      //   return dataUrl;
      // }
      // changes made on 7 may 
      case 'snapchat': {
        let data1 = data;
        let url;
        if (data.includes('https://t.snapchat.com/')) {
          let value = data.split('/').pop();
          url = `${data1}`;
          bundlename = `${data1}`
        } else {
          url = `https://www.snapchat.com/add/${data1}`;
          bundlename = `https://www.snapchat.com/add/${data1}`
        }

        let dataUrl = { url, bundlename };
        return dataUrl;
      }

      case 'skype': {
        if (this.platform.isBrowser && this.platform.ANDROID) {
          var url: any = `https://www.skype.com/en`
          var bundlename: any = `skype:${data}?chat`;
        }
        else if (this.platform.IOS && this.platform.SAFARI) {
          var url: any = `https://www.skype.com/en`
          var bundlename: any = `skype://`;
        }
        else {
          var url: any = `https://www.skype.com/en`
          var bundlename: any = `https://www.skype.com/en`;
        }
        let dataUrl = { url, bundlename };
        return dataUrl;
      }

      case 'meetup': {
        if (this.platform.isBrowser && this.platform.ANDROID) {
          var url: any = `${data}`
          var bundlename: any = `${data}`;
        }
        else if (this.platform.IOS && this.platform.SAFARI) {
          var url: any = `${data}`
          var bundlename: any = `${data}`;
        }
        else {
          var url: any = `${data}`
          var bundlename: any = `${data}`;
        }
        let dataUrl = { url, bundlename };
        return dataUrl;
      }
      case 'behance': {
        var mydata = data;
        if (!mydata.includes('https://')) {
          var pathname = mydata.split('https://')[1];
          var url: any = "https://www.behance.net/" + pathname;
          var bundlename: any = "https://www.behance.net/" + pathname;
        }
        else {
          var url: any = mydata;
          var bundlename: any = mydata;
        }
        let dataUrl = { url, bundlename };
        return dataUrl;
      }

      // case 'facebook': {
      //   if (this.platform.ANDROID && this.platform.isBrowser) {
      //     var a = data
      //     if (a.includes('id')) {
      //       var userid = a.split("id=")[1]
      //       var url: any = `${data}`
      //       var bundlename: any = `fb://profile/${userid}`;
      //     }
      //     else {
      //       var url: any = `${data}`
      //       var bundlename: any = `fb://facewebmodal/f?href=${data}`;
      //     }
      //   }
      //   else if (this.platform.IOS && this.platform.SAFARI) {
      //     var a = data
      //     if (a.includes('id')) {
      //       var userid = a.split("id=")[1]
      //       var url: any = `${data}`
      //       var bundlename: any = `fb://profile/${userid}`;
      //     }
      //     else {
      //       var url: any = `${data}`
      //       var bundlename: any = `fb://facewebmodal/f?href=${data}`;
      //     }
      //   }
      //   else {
      //     var url: any = `${data}`
      //     var bundlename: any = `${data}`;
      //   }
      //   let dataUrl = { url, bundlename };
      //   return dataUrl;
      // }

      // changes made on 8 may for mobile issue 
      case 'facebook': {
        let data1 = data;
        let url;
        if (data.includes('https://www.facebook.com/')) {
          let value = data.split('/').pop();
          url = `${data1}`;
          bundlename = `${data1}`
        } else {
          url = `https://www.facebook.com/${data1}`;
          bundlename = `https://www.facebook.com/${data1}`
        }

        let dataUrl = { url, bundlename };
        return dataUrl;
      }
      case 'twitter': {
        if (this.platform.ANDROID && this.platform.isBrowser) {
          var url: any = `https://twitter.com/${data}`;
          var bundlename: any = `twitter://user?screen_name=${data}`;
        }
        else if (this.platform.IOS && this.platform.SAFARI) {
          var url: any = `https://twitter.com/${data}`;
          var bundlename: any = `https://twitter.com/${data}`;
        }
        else {
          var url: any = `https://twitter.com/${data}`
          var bundlename: any = `https://twitter.com/${data}`;
        }
        let dataUrl = { url, bundlename };
        return dataUrl;
      }

      // changes made on 23 april before 23 april commented code is working here but we have bug 
      // case 'youtube': {
      //   if (this.platform.ANDROID && this.platform.isBrowser) {
      //     var mydata = data
      //     if (mydata.includes('watch?v=')) {
      //       var pathname = mydata.split('watch?v=')[1];
      //     }
      //     else {
      //       var pathname = mydata.split('youtu.be/')[1];
      //     }
      //     var url: any = `${data}`
      //     var bundlename: any = "https://www.youtube.com/watch?v=" + pathname;
      //   }
      //   else if (this.platform.IOS) {
      //     var mydata = data
      //     if (mydata.includes('watch?v=')) {
      //       var pathname = mydata.split('watch?v=')[1];
      //     }
      //     else {
      //       var pathname = mydata.split('youtu.be/')[1];
      //     }
      //     var url: any = `${data}`
      //     var bundlename: any = "https://www.youtube.com/watch?v=" + pathname;
      //   }
      //   else {
      //     var mydata = data
      //     if (mydata.includes('watch?v=')) {
      //       var pathname = mydata.split('watch?v=')[1];
      //     }
      //     else {
      //       var pathname = mydata.split('youtu.be/')[1];
      //     }
      //     var url: any = `${data}`
      //     var bundlename: any = "https://www.youtube.com/watch?v=" + pathname;;
      //   }
      //   let dataUrl = { url, bundlename };
      //   return dataUrl;
      // }
      case 'youtube': {
        let mydata = data;
        let pathname: string | undefined;
        let bundlename: string | undefined;

        // Check if the URL contains a standard video ID (watch?v=VIDEO_ID)
        if (mydata.includes('watch?v=')) {
          pathname = mydata.split('watch?v=')[1];
          bundlename = `https://www.youtube.com/watch?v=${pathname}`;
        }
        // Check if the URL contains a shortened video URL (youtu.be/VIDEO_ID)
        else if (mydata.includes('youtu.be/')) {
          pathname = mydata.split('youtu.be/')[1];
          bundlename = `https://www.youtube.com/watch?v=${pathname}`;
        }
        // Check if the URL is in a custom format (e.g., channel URL)
        else if (mydata.includes('@')) {
          // Extract channel or user identifier from custom URL
          const parts = mydata.split('/');
          const identifier = parts[parts.length - 1]; // Get the last part of the URL
          // Construct a URL to open the channel or user profile
          bundlename = `https://youtube.com/${identifier}`;
        }

        // Construct the URL (same as input URL)
        let url: string = `${mydata}`;

        // Construct the dataUrl object with both url and bundlename
        let dataUrl: { url: string, bundlename?: string } = { url };
        if (bundlename) {
          dataUrl.bundlename = bundlename;
        }

        return dataUrl;
      }
      case 'youtubeMusic': {
        const url = `${data}`
        const bundlename: any = `${data}`;
        let dataUrl = { url, bundlename };
        return dataUrl;
      }

      // case 'linkedin': {
      //   if (this.platform.ANDROID && this.platform.isBrowser) {
      //     var url: any = `${data}`
      //     var mydata = data
      //     if (mydata.includes('https:')) {
      //       var pathname = mydata.split('linkedin.com/in/')[1];
      //       var bundlename: any = "https://www.linkedin.com/in/" + pathname;
      //     }
      //     else {
      //       var bundlename: any = "linkedin://" + pathname;
      //     }
      //   }
      //   else if (this.platform.IOS && this.platform.SAFARI) {
      //     var url: any = `${data}`
      //     var mydata = data
      //     if (mydata.includes('https:')) {
      //       var pathname = mydata.split('linkedin.com/in/')[1];
      //       var bundlename: any = "https://www.linkedin.com/in/" + pathname;
      //     }
      //     else {
      //       var bundlename: any = "linkedin://" + pathname;
      //     }
      //   }
      //   else {
      //     var url: any = `${data}`
      //     var bundlename: any = `${data}`;
      //   }
      //   let dataUrl = { url, bundlename };
      //   return dataUrl;
      // }

      case 'linkedin': {
        const url = `${data}`
        const bundlename: any = `${data}`;
        let dataUrl = { url, bundlename };
        return dataUrl;
      }


      case 'devianArt': {
        if (this.platform.ANDROID && this.platform.isBrowser) {

          if (!(`${data}`).includes('https://')) {
            var url: any = `https://www.deviantart.com/${data}`
            var bundlename: any = `DeviantArt://profile/${data}`;

          }
          else {
            var url: any = `${data}`
            var bundlename: any = `DeviantArt://profile/${data}`;
          }
        }
        else if (this.platform.IOS && this.platform.SAFARI) {
          if (!(`${data}`).includes('https://')) {
            var url: any = `https://www.deviantart.com/${data}`
            var bundlename: any = `DeviantArt://profile/${data}`;

          }
          else {
            var url: any = `${data}`
            var bundlename: any = `DeviantArt://profile/${data}`;
          }
        }
        else {
          if (!(`${data}`).includes('https://')) {
            var url: any = `https://www.deviantart.com/${data}`
            var bundlename: any = `https://www.deviantart.com/${data}`;

          }
          else {
            var url: any = `${data}`
            var bundlename: any = `${data}`;
          }
        }
        let dataUrl = { url, bundlename };
        return dataUrl;
      }

      case 'pinterest': {
        if (this.platform.ANDROID && this.platform.isBrowser) {
          var url: any = `https://www.pinterest.com/${data}`
          var bundlename: any = `https://www.pinterest.com/${data}`;
        }
        else if (this.platform.IOS) {
          var url: any = `https://www.pinterest.com/${data}`
          var bundlename: any = `https://www.pinterest.com/${data}`;
        }
        else {
          var url: any = `https://www.pinterest.com/${data}`
          var bundlename: any = `https://www.pinterest.com/${data}`;
        }
        let dataUrl = { url, bundlename };
        return dataUrl;
      }

      case 'reddit': {
        if (this.platform.ANDROID && this.platform.isBrowser) {
          var url: any = `${data}`
          var bundlename: any = `reddit://${data}`;
        }
        else if (this.platform.IOS) {
          var url: any = `${data}`
          var bundlename: any = `reddit://${data}`;
        }
        else {
          var url: any = `https://www.reddit.com/user/${data}`
          var bundlename: any = `https://www.reddit.com/user/${data}`;
        }
        let dataUrl = { url, bundlename };
        return dataUrl;
      }

      case 'zoom': {
        const url = `${data}`
        const bundlename: any = `${data}`;
        let dataUrl = { url, bundlename };
        return dataUrl;
      }

      case 'viber': {
        if (this.platform.ANDROID && this.platform.isBrowser) {
          var url: any = `viber://chat?number=${data}`
          var bundlename: any = `viber://chat?number=${data}`;
        }
        else if (this.platform.IOS && this.platform.SAFARI) {
          var url: any = `https://account.viber.com/`;
          var bundlename: any = `viber://chat?number=${data}`;
        }
        else {
          var url: any = `https://account.viber.com/contact?number=${data}`
          var bundlename: any = `https://account.viber.com/contact?number=${data}`
        }
        let dataUrl = { url, bundlename };
        return dataUrl;
      }

      case 'microsoftTeams': {
        if (this.platform.ANDROID && this.platform.isBrowser) {
          var mydata = data
          if (mydata.includes('https:')) {
            var teampath = mydata.split('meet/')[1];
          }
          var url: any = `${data}`
          var bundlename: any = "https://teams.live.com/meet/" + teampath;
        }
        else if (this.platform.IOS && this.platform.SAFARI) {
          var mydata = data
          if (mydata.includes('https:')) {
            var pathname = mydata.split('meet/')[1];
          }
          var url: any = `${data}`
          var bundlename: any = "https://teams.live.com/meet/" + pathname;
        }
        else {
          var url: any = `${data}`
          var bundlename: any = `${data}`;
        }
        let dataUrl = { url, bundlename };
        return dataUrl;
      }

      case 'drive': {
        if (this.platform.ANDROID && this.platform.isBrowser) {
          var mydata = data
          if (mydata.includes('https')) {
            var pathname = mydata.split('google.com/')[1];
          }
          var url: any = `${data}`
          var bundlename: any = " https://drive.google.com/" + pathname;
        }
        else if (this.platform.IOS && this.platform.SAFARI) {
          var mydata = data
          if (mydata.includes('https')) {
            var pathname = mydata.split('google.com/')[1];
          }
          var url: any = `${data}`
          var bundlename: any = " https://drive.google.com/" + pathname;
        }
        else {
          var url: any = `${data}`
          var bundlename: any = `${data}`;
        }
        let dataUrl = { url, bundlename };
        return dataUrl

      }

      case 'qq': {

        const url = `${data}`
        const bundlename: any = `${data}`;


        let dataUrl = { url, bundlename };
        return dataUrl;
      }

      case 'kik': {
        if (this.platform.ANDROID && this.platform.isBrowser) {
          if (!(`${data}`).includes('https')) {
            var url: any = `https://kik.me/${data}`
            var bundlename: any = `https://kik.me/${data}`;
          }
          else {
            var url: any = `${data}`
            var bundlename: any = `${data}`;
          }

        }
        else if (this.platform.IOS) {
          if (!(`${data}`).includes('https')) {
            var url: any = `https://kik.me/${data}`
            var bundlename: any = `https://kik.me/${data}`;
          }
          else {
            var url: any = `${data}`
            var bundlename: any = `${data}`;
          }
        }
        else {
          if (!(`${data}`).includes('https')) {
            var url: any = `https://kik.me/${data}`
            var bundlename: any = `https://kik.me/${data}`;
          }
          else {
            var url: any = `${data}`
            var bundlename: any = `${data}`;
          }
        }
        let dataUrl = { url, bundlename };
        return dataUrl
      }

      case 'tumblr': {
        if (this.platform.ANDROID && this.platform.isBrowser) {
          var mydata = data
          if (mydata.includes('https')) {
            var pathname = mydata.split('https://')[1];
          }
          else {
            var pathname = mydata.split('http://')[1];
          }
          var pathname1 = pathname.split('.tumblr.com')[0];
          var url: any = `${data}`
          var bundlename: any = "https://" + pathname1 + ".tumblr.com";
        }

        else if (this.platform.IOS) {
          var mydata = data
          if (mydata.includes('https')) {
            var pathname = mydata.split('https://')[1];
          }
          else {
            var pathname = mydata.split('http://')[1];

          }
          var pathname1 = pathname.split('.tumblr.com')[0];
          var url: any = `${data}`
          var bundlename: any = "tumblr://x-callback-url/blog?blogName=" + pathname1;
        }

        else {
          var url: any = `${data}`
          var bundlename: any = `${data}`;
        }
        let dataUrl = { url, bundlename };
        return dataUrl;
      }

      case 'tinder': {
        if (this.platform.ANDROID && this.platform.isBrowser) {
          var url: any = `${data}`
          var bundlename: any = `${data}`;
        }
        else if (this.platform.IOS) {
          var url: any = `${data}`
          var bundlename: any = `${data}`;
        }
        else if (this.platform.SAFARI) {
          var url: any = `${data}`
          var bundlename: any = `${data}`;
        }
        else {
          var url: any = `${data}`
          var bundlename: any = `${data}`;
        }
        let dataUrl = { url, bundlename };
        return dataUrl;

      }

      case 'twitch': {
        const url = `https://www.twitch.tv/${data}`;
        const bundlename: any = `https://www.twitch.tv/${data}`;
        let dataUrl = { url, bundlename };
        return dataUrl;
      }

      case 'indeed': {
        if (this.platform.ANDROID && this.platform.isBrowser) {
          var url: any = `${data}`
          var bundlename: any = `${data}`;
        }
        else if (this.platform.IOS) {
          var url: any = `${data}`
          var bundlename: any = `${data}`;
        }
        else if (this.platform.SAFARI) {
          var url: any = `${data}`
          var bundlename: any = `${data}`;
        }
        else {
          var url: any = `${data}`
          var bundlename: any = `${data}`;
        }
        let dataUrl = { url, bundlename };
        return dataUrl;
      }

      case 'quora': {
        if (this.platform.ANDROID && this.platform.isBrowser) {
          var url: any = `${data}`
          var bundlename: any = `quora://${data}`;
        }
        else if (this.platform.IOS && this.platform.SAFARI) {
          var mydata = data
          if (mydata.includes('https:')) {
            var pathname = mydata.split('profile/')[1];
          }
          var url: any = `${data}`
          var bundlename: any = "https://www.quora.com/profile/" + pathname;
        }
        else {
          var url: any = `${data}`
          var bundlename: any = `${data}`;
        }
        let dataUrl = { url, bundlename };
        return dataUrl;
      }


      case 'dribbble': {
        if (this.platform.ANDROID && this.platform.isBrowser) {
          var url: any = `${data}`
          var bundlename: any = `${data}`;
        }
        else if (this.platform.IOS) {
          var url: any = `${data}`
          var bundlename: any = `${data}`;
        }
        else {
          var url: any = `${data}`
          var bundlename: any = `${data}`;
        }
        let dataUrl = { url, bundlename };
        return dataUrl;
      }

      case 'spotify': {
        if (this.platform.ANDROID && this.platform.isBrowser) {
          var url: any = `${data}`
          var bundlename: any = `spotify://${data}`;
        }

        else if (this.platform.IOS && this.platform.SAFARI) {
          var url: any = `${data}`
          var mydata = data
          if (mydata.includes('https:')) {
            var pathname = mydata.split('spotify.com/')[1];
          }
          var bundlename: any = "https://open.spotify.com/" + pathname;
        }

        else {
          var url: any = `${data}`
          var bundlename: any = `${data}`;
        }
        let dataUrl = { url, bundlename };
        return dataUrl;
      }

      case 'soundcloud': {
        if (this.platform.ANDROID && this.platform.isBrowser) {
          var url: any = `${data}`
          var bundlename: any = `soundcloud://${data}`;
        }
        else if (this.platform.IOS) {
          var url: any = `${data}`
          var bundlename: any = `soundcloud://${data}`;
        }

        else {
          var url: any = `${data}`
          var bundlename: any = `${data}`;
        }
        let dataUrl = { url, bundlename };
        return dataUrl;
      }

      case 'qzone': {
        const url = `${data}`
        const bundlename: any = `${data}`;
        let dataUrl = { url, bundlename };
        return dataUrl;
      }

      case 'weibo': {
        const url = `http://ir.weibo.com`
        const bundlename: any = `http://ir.weibo.com`;
        let dataUrl = { url, bundlename };
        return dataUrl;
      }

      case 'renren': {
        const url = `http://www.renren.com`
        const bundlename: any = `http://www.renren.com`;
        let dataUrl = { url, bundlename };
        return dataUrl;
      }

      case 'vimeo': {
        const url = `${data}`
        const mydata = data.split("https://")[1];
        const bundlename: any = `https://${mydata}`;
        let dataUrl = { url, bundlename };
        return dataUrl;
      }

      case 'link': {
        const url = `${data}`
        const bundlename: any = `${data}`;
        localStorage.setItem(slug, JSON.stringify(data));
        let dataUrl = { url, bundlename };
        return dataUrl;
      }

      case 'yelp': {
        var a = data;
        if (a.includes('https:')) {
          var pathname = a.split('yelp.co.uk/')[1];
        }
        if (this.platform.ANDROID && this.platform.isBrowser) {
          var url: any = "https://www.yelp.co.uk/" + pathname;
          var bundlename: any = "https://www.yelp.co.uk/" + pathname;
        }
        else if (this.platform.IOS && this.platform.SAFARI) {
          var url: any = `${data}`;
          var mydata = data;
          if (mydata.includes('https:')) {
            var pathname = mydata.split('yelp.co.uk/')[1];
          }
          var bundlename: any = "https://www.yelp.co.uk/" + pathname;
        }
        else {
          var url: any = `${data}`
          var bundlename: any = `${data}`;
        }
        let dataUrl = { url, bundlename };
        return dataUrl;
      }

      case 'trustpilot': {
        const url = `https://www.trustpilot.com`
        const bundlename: any = `https://www.trustpilot.com`;
        let dataUrl = { url, bundlename };
        return dataUrl;
      }

      case 'doctolib': {
        const url = `${data}`
        const bundlename: any = `${data}`;

        let dataUrl = { url, bundlename };
        return dataUrl;
      }

      case 'lost_found': {
        const url = `${environment.redirectLinkBaseUrl}/slug-profile/${slug}/${data.base64_id}`;
        const bundlename: any = `${environment.redirectLinkBaseUrl}/slug-profile/${slug}/${data.base64_id}`;
        let dataUrl = { url, bundlename };
        return dataUrl;
      }

      case 'emenu': {
        const url = `${environment.redirectLinkBaseUrl}/slug-profile/${slug}/${data.base64_id}`
        const bundlename: any = `${environment.redirectLinkBaseUrl}/slug-profile/${slug}/${data.base64_id}`;
        let dataUrl = { url, bundlename };
        return dataUrl;
      }

      case 'calendar': {
        const url = `${data}`
        const bundlename: any = `${data}`;
        let dataUrl = { url, bundlename };
        return dataUrl;
      }

      case 'cv': {
        const url = `${data}`
        const bundlename: any = `${data}`;
        let dataUrl = { url, bundlename };
        return dataUrl;
      }


      case 'event': {
        const url = environment.export_event + data
        const bundlename: any = environment.export_event + data
        let dataUrl = { url, bundlename };
        return dataUrl;
      }
      case 'contact': {
        const url: any = `${environment.redirectLinkBaseUrl}/slug-profile/${slug}/${data.base64_id}`;
        const bundlename: any = `${environment.redirectLinkBaseUrl}/slug-profile/${slug}/${data.base64_id}`;
        let dataUrl = { url, bundlename };
        return dataUrl;
      }

      case 'wechat': {


        const url: any = `${data}`;
        const bundlename: any = `${data}`;

        let dataUrl = { url, bundlename };
        return dataUrl;
      }

      case 'telegram': {
        if (this.platform.ANDROID && this.platform.isBrowser) {
          var url: any = `https://telegram.me/${data}`
          var bundlename: any = `https://telegram.me/${data}`;
        }

        else if (this.platform.IOS) {
          var url: any = `https://telegram.me/${data}`
          var bundlename: any = `tg://msg?text=&to=@${data}`;

        }
        else {
          var url: any = `https://telegram.me/${data}`
          var bundlename: any = `https://telegram.me/${data}`;
        }


        let dataUrl = { url, bundlename };
        return dataUrl
      }

      case 'linktree': {
        const url: any = `${environment.redirectLinkBaseUrl}/slug-profile/${slug}/${data.base64_id}`;
        const bundlename: any = `${environment.redirectLinkBaseUrl}/slug-profile/${slug}/${data.base64_id}`;
        let dataUrl = { url, bundlename };
        return dataUrl;
      }
      case 'emergency': {
        const url = `${environment.redirectLinkBaseUrl}/slug-profile/${slug}/${data.base64_id}`;
        const bundlename: any = `${environment.redirectLinkBaseUrl}/slug-profile/${slug}/${data.base64_id}`;
        localStorage.setItem(slug, JSON.stringify(data));
        let dataUrl = { url, bundlename };
        return dataUrl;
      }


      case 'whatsApp': {
        const url = `http://api.whatsapp.com/send?phone=${data}&text=${title}`
        const bundlename: any = `http://api.whatsapp.com/send?phone=${data}&text=${title}`;
        let dataUrl = { url, bundlename };
        return dataUrl;
      }

      case 'email': {
        const url: any = `mailto:${data}`;
        const bundlename: any = `mailto:${data}`;
        let dataUrl = { url, bundlename };
        return dataUrl;
      }


      case 'messenger': {
        if (`${data}`.includes("id")) {
          let data1 = `${data}`
          data1.split("=");
          let splitdata = data1.split("=");
          var url: any = 'https://www.messenger.com/t/' + splitdata[1];
          var bundlename: any = 'https://www.messenger.com/t/' + splitdata[1];
        }
        else {
          var data2 = `${data}`
          var data3 = data2.split("https://www.facebook.com/")[1];
          var url: any = 'https://www.messenger.com/t/' + data3;
          var bundlename: any = 'https://www.messenger.com/t/' + data3;
        }
        let dataUrl = { url, bundlename };
        return dataUrl;
      }

      case 'paypal': {
        if (data.includes("https://www.paypal.me/")) {
          var url: any = `${data}`;
          var bundlename: any = `${data}`;
        }
        else {
          var url: any = `https://www.paypal.me/${data}`;
          var bundlename: any = `https://www.paypal.me/${data}`;
        }
        let dataUrl = { url, bundlename };
        return dataUrl;
      }

      case 'music': {
        const url = `${data}`
        const bundlename: any = `${data}`;
        let dataUrl = { url, bundlename };
        return dataUrl;
      }

      case 'notes': {
        const url: any = `${environment.redirectLinkBaseUrl}/notes/${data.base64_id}`;
        const bundlename: any = `${environment.redirectLinkBaseUrl}/notes/${data.base64_id}`;
        localStorage.setItem(slug, JSON.stringify(data));
        let dataUrl = { url, bundlename };
        return dataUrl;
      }

      case 'waze': {


        const url = `${data}`;
        const bundlename: any = `${data}`;

        let dataUrl = { url, bundlename };
        return dataUrl;
      }

      case 'tripAdvisor': {
        if (this.platform.ANDROID && this.platform.isBrowser) {
          var url: any = `${data}`
          var mydata = data
          if (mydata.includes('https:')) {
            var pathname = mydata.split('tripadvisor.')[1];
          }
          var bundlename: any = "https://www.tripadvisor." + pathname;
        }

        else if (this.platform.IOS && this.platform.SAFARI) {
          var url: any = `${data}`;
          var mydata = data
          if (mydata.includes('https:')) {
            var bundlename: any = `${data}`;
          }
          else {
            var bundlename: any = "https://www.tripadvisor.com/" + data;
          }
        }

        else {
          var url: any = `${data}`
          var bundlename: any = `${data}`;
        }

        let dataUrl = { url, bundlename };
        return dataUrl;
      }

      case 'messages': {
        if (this.platform.IOS && this.platform.SAFARI) {
          var url: any = `sms://${title}&body=${data}`
          var bundlename: any = `sms://${title}&body=${data}`;
        }
        else {
          var url: any = `sms:${title}&body=${data}`
          var bundlename: any = `sms:${title}&body=${data}`;
        }
        let dataUrl = { url, bundlename };
        return dataUrl;
      }


      case 'googleMaps': {
        const url = `http://maps.google.co.in/maps?q=${data}`
        const bundlename: any = ` http://maps.google.co.in/maps?q=${data}`;
        let dataUrl = { url, bundlename };
        return dataUrl;
      }

      case 'chrome': {
        if (this.platform.ANDROID && this.platform.isBrowser) {
          var url: any = `${data}`
          var bundlename: any = `googlechrome://${data}`;
        }
        else if (this.platform.IOS && this.platform.SAFARI) {
          var url: any = `${data}`
          var mydata = data
          if (mydata.includes('https:')) {
            var pathname = mydata.split('https://')[1];
            var bundlename: any = "googlechrome://" + pathname;
          }
          else {
            var bundlename: any = "https://" + pathname;
          }
        }
        else {
          var url: any = `${data}`
          var bundlename: any = `${data}`;
        }
        let dataUrl = { url, bundlename };
        return dataUrl;
      }

      case 'safari': {
        const url = `${data}`
        const bundlename: any = `${data}`;
        let dataUrl = { url, bundlename };
        return dataUrl;
      }

      case 'lydia': {
        const url = `https://lydia-app.com`
        const bundlename: any = ` https://lydia-app.com`;
        let dataUrl = { url, bundlename };
        return dataUrl;
      }

      case 'paylibb': {
        const url = `https://www.paylib.fr/activer-paylib/banque-bcp`
        const bundlename: any = `https://www.paylib.fr/activer-paylib/banque-bcp`;
        let dataUrl = { url, bundlename };
        return dataUrl;
      }


      case 'maps': {
        const url = `http://maps.apple.com/?address=${data}`;
        const bundlename: any = `http://maps.apple.com/?address=${data}`;
        let dataUrl = { url, bundlename };
        return dataUrl;
      }



      case 'uber': {

        var url: any = `${data}`
        var bundlename: any = `${data}`
        let dataUrl = { url, bundlename };
        return dataUrl;
      }

      case 'mappy': {
        const url = `https://fr.mappy.com/plan#/${data}`;
        const bundlename: any = `https://fr.mappy.com/plan#/${data}`;
        let dataUrl = { url, bundlename };
        return dataUrl;
      }

      case 'googleMeet': {
        const url = `${data}`
        const bundlename: any = `${data}`;
        let dataUrl = { url, bundlename };
        return dataUrl;
      }

      case 'botim': {
        const url = `https://web.botim.me/#/${data}`
        const bundlename: any = `https://botim.me/#/${data}`;
        let dataUrl = { url, bundlename };
        return dataUrl;
      }
      case 'citymapper': {
        const url = `${data}`
        const bundlename: any = `${data}`;
        let dataUrl = { url, bundlename };
        return dataUrl;
      }
      case 'moovit': {
        const url = `${data}`
        const bundlename: any = `${data}`;
        let dataUrl = { url, bundlename };
        return dataUrl;
      }

      case 'mappy': {
        const url = `https://fr.mappy.com/plan#/${data}`
        const bundlename: any = `https://fr.mappy.com/plan#/${data}`;
        let dataUrl = { url, bundlename };
        return dataUrl;
      }

      // ***************************** New Links *****************************

      case 'calendly': {
        const url = `${data}`
        const bundlename: any = `${data}`;
        let dataUrl = { url, bundlename };
        return dataUrl;
      }

      case 'opensea': {
        const url = `${data}`
        const bundlename: any = `${data}`;
        let dataUrl = { url, bundlename };
        return dataUrl;
      }

      case 'airbnb': {
        const url = `${data}`
        const bundlename: any = `${data}`;
        let dataUrl = { url, bundlename };
        return dataUrl;
      }

      case 'patreon': {
        const url = `${data}`
        const bundlename: any = `${data}`;
        let dataUrl = { url, bundlename };
        return dataUrl;
      }

      case 'onlyfans': {
        const url = `${data}`
        const bundlename: any = `${data}`;
        let dataUrl = { url, bundlename };
        return dataUrl;
      }

      case 'upwork': {
        const url = `${data}`
        const bundlename: any = `${data}`;
        let dataUrl = { url, bundlename };
        return dataUrl;
      }

      case 'fiverr': {
        const url = `${data}`
        const bundlename: any = `${data}`;
        let dataUrl = { url, bundlename };
        return dataUrl;
      }

      case 'eventbrite': {
        const url = `${data}`
        const bundlename: any = `${data}`;
        let dataUrl = { url, bundlename };
        return dataUrl;
      }

      case 'github': {
        const url = `${data}`
        const bundlename: any = `${data}`;
        let dataUrl = { url, bundlename };
        return dataUrl;
      }

      case 'freelancer': {
        const url = `${data}`
        const bundlename: any = `${data}`;
        let dataUrl = { url, bundlename };
        return dataUrl;
      }



      case 'linktree': {
        const url = `${data}`
        const bundlename: any = `${data}`;
        let dataUrl = { url, bundlename };
        return dataUrl;
      }

      case 'booking': {
        const url = `${data}`
        const bundlename: any = `${data}`;
        let dataUrl = { url, bundlename };
        return dataUrl;
      }

      case 'etsy': {
        const url = `${data}`
        const bundlename: any = `${data}`;
        let dataUrl = { url, bundlename };
        return dataUrl;
      }

      case 'ratp': {
        const url = `${data}`
        const bundlename: any = `${data}`;
        let dataUrl = { url, bundlename };
        return dataUrl;
      }

      case 'amazon': {
        const url = `${data}`
        const bundlename: any = `${data}`;
        let dataUrl = { url, bundlename };
        return dataUrl;
      }

      case 'embedvideo': {
        const url = `${data}`
        const bundlename: any = `${data}`;
        let dataUrl = { url, bundlename };
        return dataUrl;
      }

      case 'applink': {
        const url = `${data}`
        const bundlename: any = `${data}`;
        let dataUrl = { url, bundlename };
        return dataUrl;
      }

      case 'clubhouse': {
        const url = `${data}`
        const bundlename: any = `${data}`;
        let dataUrl = { url, bundlename };
        return dataUrl;
      }

      case 'facetime': {
        const url = `${data}`
        const bundlename: any = `${data}`;
        let dataUrl = { url, bundlename };
        return dataUrl;
      }

      case 'slack': {
        const url = `${data}`
        const bundlename: any = `${data}`;
        let dataUrl = { url, bundlename };
        return dataUrl;
      }

      case 'discord': {
        const url = `${data}`
        const bundlename: any = `${data}`;
        let dataUrl = { url, bundlename };
        return dataUrl;
      }

      case 'wise': {
        const url = `${data}`
        const bundlename: any = `${data}`;
        let dataUrl = { url, bundlename };
        return dataUrl;
      }

      case 'revolut': {
        const url = `${data}`
        const bundlename: any = `${data}`;
        let dataUrl = { url, bundlename };
        return dataUrl;
      }

      case 'freelancer': {
        const url = `${data}`
        const bundlename: any = `${data}`;
        let dataUrl = { url, bundlename };
        return dataUrl;
      }

      case 'reviews': {
        const url = `${data}`
        const bundlename: any = `${data}`;
        let dataUrl = { url, bundlename };
        return dataUrl;
      }

      case 'reservations': {
        const url = `${data}`
        const bundlename: any = `${data}`;
        let dataUrl = { url, bundlename };
        return dataUrl;
      }

      case 'podcasts': {
        const url = `${data}`
        const bundlename: any = `${data}`;
        let dataUrl = { url, bundlename };
        return dataUrl;
      }

      case 'customlink': {
        const url = data.includes('https') || data.includes('http') ? `${data}` : `https://${data}`
        const bundlename: any = data.includes('https') || data.includes('http') ? `${data}` : `https://${data}`;
        let dataUrl = { url, bundlename };
        return dataUrl;
      }

      // ***************************** New Links *****************************


      case 'phone': {
        this.spinner.hide();
        const url = `${data}`
        // document.location.href = "tel:+" + url
        // changes made on 7 may
        document.location.href = "tel:" + url
      }
    }
  }


  showSpinner(): void {
    this.spinner.show();
  }
  hideSpinner(): void {
    this.spinner.hide();
  }

  ref(): void {
  }
}


