
<body style="background-color: white; height: 100vh;">
    <div class="main-wrap">
       <div class="main-inner">
            <div class="top-sec">
                <div class="logo-wrap">
                    <img src="https://niha.io/wp-content/uploads/2024/03/niha_logo-120x43.png">
                </div>
                <div class="text-sec">
                    <h2>Niha- Digital Business Card</h2>
                    <p>Click below to download our app</p>
                </div>
            </div>
            <div class="sub_img">
                <img src="https://niha.io/wp-content/themes/astra/assets/images/digitalbusinesscard.png" alt="">
            </div>
            <div class="button-sec">

                <!-- changes made on 7 may start -->
                <a href="https://play.google.com/store/apps/details?id=com.nfc.niha&hl=en_IN&gl=US"><img src="https://niha.app/assets/images/playstore.svg"></a>
                <a href="https://apps.apple.com/us/app/niha-digital-business-card/id6443953122"><img src="https://niha.app/assets/images/appstore.svg"></a>
                <!-- changes made on 7 may end -->
                <!-- <a href="https://play.google.com/store/apps/details?id=com.b_in_touch&pli=1"><img src="assets/images/playstore.svg"></a> -->
                <!-- <a href="https://apps.apple.com/us/app/b-in-touch-app/id1619743993"><img src="assets/images/appstore.svg"></a> -->
            </div>
            <p class="name-sec"><span class="name">Harish’s</span> profile will be automatically added to your connections in the app</p>

       </div>       
    </div>
</body>