<ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "medium" color = "#fff" type = "square-jelly-box" [fullScreen] = "true"></ngx-spinner>
<body>
    <div class="main-wrap">
        <div class="splash-sec">
            <img class="img-fluid main_logo" src="assets/images/splash_logo.png">
            <img class="img-fluid" src="assets/images/logo_name.png">
        </div>
    </div>
</body>

<ng-template #mymodal let-modal>
    <div id="member_status" class="login_me" role="dialog">
        <!-- Modal content-->
        <div class="modal-content">
            <button type="button" style="outline: none;" class="close close_modal" (click)="modalService.dismissAll()" data-dismiss="modal" aria-label="Close">
				<span aria-hidden="true">&times;</span> </button>
            <div class="modal-header">
                <p>Long press on QR Code to connect</p>
            </div>
            <div class="modal-body">
                <form class="member_status_form">
                    <div class="text-center">
                        <img [src]="qrimg" alt="demo" style="height: 150px;width: 150px;">
                    </div>
                </form>
            </div>
        </div>
    </div>
</ng-template>