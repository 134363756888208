import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

// ***************** Angular Firebase Module ***********************************
import { AngularFireModule } from '@angular/fire';
import {AngularFireMessagingModule} from '@angular/fire/messaging'
import { environment } from 'src/environments/environment';
import {HttpClientModule} from '@angular/common/http';
import {MessagingService} from './services/message/messaging.service';
import {ToastrModule} from 'ng6-toastr-notifications';
import { EventsService } from './services/event/events.service';


@NgModule({
  declarations: [
    AppComponent,
    
   ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    CommonModule,
    BrowserAnimationsModule,
    AngularFireMessagingModule,
    HttpClientModule,
    AngularFireModule.initializeApp(environment.firebase),
    ToastrModule.forRoot(),
    
  ],
  bootstrap: [AppComponent],
  providers:[MessagingService,EventsService]
})
export class AppModule {}
