import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthenticationGuard } from './guard/authentication.guard';
import { AppNotesComponent } from './Pages/dashboard/app-notes/app-notes.component';
import { NfcTagComponent } from './Pages/dashboard/nfc-tag/nfc-tag.component';
import { QrAppServiceComponent } from './Pages/dashboard/qr-app-service/qr-app-service.component';
import { SlugProfileComponent } from './Pages/dashboard/slug-profile/slug-profile.component';
import { InvitationComponent } from './Pages/dashboard/invitation/invitation.component';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./Pages/authentication/authentication.module').then(m => m.AuthenticationModule)
  },
  {
    path: 'profile',
    loadChildren: () => import('./Pages/profile-page/profile-page.module').then(m => m.ProfilePageModule)
  },

  {
    path: 'application',
    loadChildren: () => import('./Pages/applications/applications.module').then(m => m.ApplicationsModule),
    canActivate: [AuthenticationGuard]
  },

  {
    path: 'dashboard',
    loadChildren: () => import('./Pages/dashboard/dashboard.module').then(m => m.DashboardModule),
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'nfc/:id',
    component: NfcTagComponent,
  },
  
  {
    path: 'notes/:id',
    component: AppNotesComponent,
  },
  {
    path: 'slug-profile/:slug/:id',
    component: SlugProfileComponent,
  },

  {
    path: 'app/:slug/:id',
    component: QrAppServiceComponent,
  },
  // changes made 7 may start
  {
    path: 'invitation',       
    component: InvitationComponent,
  },
// changes made 7 may end 
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
